/* global $ */
import BaseClass from '../base/baseClass';
import {Tooltip} from 'bootstrap';

class App extends BaseClass
{
    init()
    {
        $('body').css('opacity', 1);
        this.initStickyMenu();
        this.initTooltips();
        this.initAos();
        this.readmoreArticle();
        console.log('App initialized');
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 150) {
                $('.header-top').addClass('fixed');
            } else {
                $('.header-top').removeClass('fixed');
            }
        };
        stickyNav();
        $(window).scroll(() => {
            stickyNav();
        });

        $(".navbar-toggler").on('click', function () {
            $('body').toggleClass("menuOpen");
            $('.navbar-collapse').toggleClass("open");
        });
    }

    initTooltips()
    {
        $.each($('[data-bs-toggle="tooltip"]'), function (i, elem) {
            new Tooltip(elem);
        });
        $(".navbar-toggler").click(function () {
            $('body').toggleClass("menu-open");
        });
    }
    readmoreArticle()
    {
        $('.story-readmore').readmore({
            speed: 75,
            collapsedHeight: 132,
            moreLink: '<a class="card-link btn btn-outline-primary mt-3 mt-xl-4_5" href="#">Read more</a>',
            lessLink: '<a class="card-link btn btn-outline-primary mt-3 mt-xl-4_5" href="#">Read Less</a>',
        });
    }

    initAos() {
        $(function () {
            var AOS = require('aos');
            AOS.init({
                offset: 100,
                duration: 700,
                easing: "ease-out-quad",
                once: true
            });
            window.addEventListener('load', AOS.refresh);
            document.querySelectorAll('img')
                .forEach((img) =>
                    img.addEventListener('load', () =>
                        AOS.refresh()
                    )
                );
        });
    }
}

export default App;