import App from './components/app';
import Slick from './components/slick';
import Forms from './components/forms';
import Teams from './components/teams';
import Features from './components/features';
import Property from './components/property';
import Bespoke from './components/bespoke';
import Common from './components/common';

window.App = new App();
window.Forms = new Forms();
window.Slick = new Slick();
window.Teams = new Teams();
window.Features = new Features();
window.Property = new Property();
window.Bespoke = new Bespoke();
window.Common = new Common();
